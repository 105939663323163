import React from 'react'
import { usePersistentState } from 'AppRoot/hooks'
import { Switch, Route } from 'react-router-dom'

import {
  About,
  CookiePolicy,
  Contact,
  EmailAuthorization,
  EmailRedirect,
  LandingPage,
  PrivacyPolicy,
  TermsOfService
} from './pages'

export default function App() {
  // In case the user comes in on a page other than the main landing page
  const queryString = window.location.search.trim()
  const [initialQueryString, setInitialQueryString] = usePersistentState(
    'initialQueryString',
    queryString
  )

  if (initialQueryString.trim() === '' && queryString.length > 0) {
    setInitialQueryString(queryString)
  }

  return (
    <Switch>
      <Route path="/auth/redirect">
        <EmailRedirect />
      </Route>

      <Route path="/about">
        <About />
      </Route>

      <Route path="/cookie-policy">
        <CookiePolicy />
      </Route>

      <Route path="/contact">
        <Contact />
      </Route>

      <Route path="/email-auth">
        <EmailAuthorization />
      </Route>

      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>

      <Route path="/terms-of-service">
        <TermsOfService />
      </Route>

      <Route path="/">
        <LandingPage />
      </Route>
    </Switch>
  )
}
