import React from 'react'

import { InfoPageHeader, Footer } from 'Components'

export default function TermsOfService() {
  return (
    <>
      <div className="text-darkerGrey text-lg">
        <InfoPageHeader
          pageTitle="Terms of Service"
          subTitle="Last updated: July 15th, 2022"
        />

        <div
          id="terms-of-service"
          className="legal-text text-midGrey text-lg mx-24 lg:mx-auto max-w-text"
        >
          <h2>Introduction</h2>
          <p>
            When you use our Services you agree to all of these terms. Your use
            of our Services is also subject to our Cookie Policy and our Privacy
            Policy, which covers how we collect, use, share, and store your
            personal information.
          </p>
          <p>
            You agree that by clicking “Join Now”, “Sign Up” or similar,
            registering, accessing or using our services (described below), you
            are agreeing to enter into a legally binding contract with
            HelloPrivacy LLC (even if you are using our Services on behalf of a
            company). If you do not agree to this contract (“Contract” or “User
            Agreement”), do not click “Join Now” (or similar) and do not access
            or otherwise use any of our Services.
          </p>

          <ol className="list-decimal">
            <li>
              <strong>Services</strong>
              <p>
                This Contract applies to HelloPrivacy LLC, DBA BrandYourself
                (henceforth called <strong>"HelloPrivacy"</strong>). It also
                applies to HelloPrivacy-branded apps and other
                HelloPrivacy-related sites, apps, communications and other
                services that state that they are offered under this Contract
                (“Services”), including the offsite collection of data for those
                Services. Registered users of our Services are “Members” and
                unregistered users are “Visitors”. This Contract applies to
                both.
              </p>
            </li>
            <li>
              <strong>HelloPrivacy</strong>
              <p>
                You are entering into this Contract with HelloPrivacy LLC (also
                referred to as “we” and “us”). Your personal data provided to,
                or collected by or for, our Services is controlled by
                HelloPrivacy LLC
              </p>
            </li>
            <li>
              <strong>Members and Visitors</strong>
              <p>
                When you register and join the HelloPrivacy Service, you become
                a Member. If you have chosen not to register for our Services,
                you may access certain features as a visitor.
              </p>
            </li>
            <li>
              <strong>Change</strong>
              <p>
                We may modify this Contract, our Privacy Policy and our Cookies
                Policies from time to time. If we make material changes to it,
                we will provide you notice through our Services, or by other
                means, to provide you the opportunity to review the changes
                before they become effective. If you object to any changes, you
                may close your account by contacting support at (646) 863-8226.
                Your continued use of our Services after we publish or send a
                notice about our changes to these terms means that you are
                consenting to the updated terms.
              </p>
            </li>
            <li>
              <strong>Obligations</strong>
              <ol className="list-decimal">
                <li>
                  <strong>Service Eligibility</strong>
                  <p>Here are some promises you make to us in this Contract:</p>
                  <ol className="list-lowerAlpha">
                    <li>
                      You’re eligible to enter into this Contract and you are at
                      least our “Minimum Age.” The Services are not for use by
                      anyone under the age of 16. To use the Services, you agree
                      that: (1) you must be the “Minimum Age” (described below)
                      or older; (2) you will only have one HelloPrivacy account,
                      which must be in your real name; and (3) you are not
                      already restricted by HelloPrivacy from using the
                      Services.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Your Account</strong>
                  <p>
                    Members are account holders. You agree to: (1) try to choose
                    a strong and secure password; (2) keep your password secure
                    and confidential; (3) not transfer any part of your account
                    (e.g., connections) and (4) follow the law and our list of
                    Dos and Don’ts. You are responsible for anything that
                    happens through your account unless you close it or report
                    misuse.
                  </p>
                  <p>
                    As between you and others (including your employer, school
                    or university), your account belongs to you. However, if the
                    Services were purchased by another party for you to use
                    (e.g. your employer, school or university), the party paying
                    for such Service has the right to control access to and get
                    reports on your use of such paid Service; however, they do
                    not have rights to your personal account.
                  </p>
                </li>
                <li>
                  <strong>Payment</strong>
                  <p>
                    If you buy any of our paid Services (“Premium Services”),
                    you agree to pay us the applicable fees and taxes and to
                    additional terms specific to the paid Services. Failure to
                    pay these fees will result in the termination of your paid
                    Services. Also, you agree that:
                  </p>
                  <ol className="list-lowerAlpha">
                    <li>
                      Your purchase may be subject to foreign exchange fees or
                      differences in prices based on location (e.g. exchange
                      rates).
                    </li>
                    <li>
                      We may store and continue billing your payment method
                      (e.g. credit card) even after it has expired, to avoid
                      interruptions in your Services and to use to pay other
                      Services you may buy.
                    </li>
                    <li>
                      If you purchase a subscription, your payment method
                      automatically will be charged at the start of each
                      subscription period for the fees and taxes applicable to
                      that period. To avoid future charges, cancel before the
                      renewal date.
                    </li>
                    <li>
                      All of your purchases of Services are subject to
                      HelloPrivacy’s{' '}
                      <a href="https://helloprivacy.com/university/guide/faq##what-is-your-premium-diy-tool-s-refund-policy-">
                        refund policy
                      </a>
                      .
                    </li>
                    <li>
                      We may calculate taxes payable by you based on the billing
                      information that you provide us at the time of purchase.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Notices and Service Messages</strong>
                  <p>
                    You agree that we will provide notices to you in the
                    following ways: (1) a notice within the Service, or (2) a
                    message sent to the contact information you provided us
                    (e.g., email, mobile number, physical address). You agree to
                    keep your contact information up to date.
                  </p>
                </li>
                <li>
                  <strong>Sharing</strong>
                  <p>
                    We are not obligated to publish any information or content
                    on our Service and can remove it in our sole discretion,
                    with or without notice.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>Rights and Limits</strong>
              <ol className="list-decimal">
                <li>
                  <strong>Your License to HelloPrivacy</strong>
                  <p>
                    As between you and HelloPrivacy, you own the content and
                    information that you submit or post to the Services and you
                    are only granting HelloPrivacy and our affiliates the
                    following non-exclusive license: a worldwide, transferable
                    and sublicensable right to use, copy, modify, distribute,
                    publish, and process, information and content that you
                    provide through our Services, without any further consent,
                    notice and/or compensation to you or others.
                  </p>
                  <p>These rights are limited in the following ways:</p>
                  <ol className="list-lowerAlpha">
                    <li>
                      You can end this license for specific content by deleting
                      such content from the Services, or generally by closing
                      your account, except for the reasonable time it takes to
                      remove from backup and other systems. We will not include
                      your content in advertisements for the products and
                      services of third parties to others without your separate
                      consent (including sponsored content). However, we have
                      the right, without payment to you or others, to serve ads
                      near your content and information, and your social actions
                      on sponsored content and company pages may be visible, as
                      noted in the Privacy Policy.
                    </li>
                    <li>
                      While we may edit and make formatting changes to your
                      content (such as translating it, modifying the size,
                      layout or file type or removing metadata), we will not
                      modify the meaning of your expression.
                    </li>
                    <li>
                      Because you own your content, you may choose to make it
                      available to others.
                    </li>
                    <li>
                      You agree that we may access, store and use any
                      information that you provide in accordance with the terms
                      of the Privacy Policy and your choices (including
                      settings).
                    </li>
                    <li>
                      By submitting suggestions or other feedback regarding our
                      Services to HelloPrivacy, you agree that HelloPrivacy can
                      use and share (but does not have to) such feedback for any
                      purpose without compensation to you.
                    </li>
                    <li>
                      You agree to only provide content or information that does
                      not violate the law nor anyone’s rights (including
                      intellectual property rights). You also agree that your
                      profile information will be truthful. HelloPrivacy may be
                      required by law to remove certain information or content
                      in certain countries.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Service Availability</strong>
                  <p>
                    We may change, suspend or end any Service, or change and
                    modify prices prospectively in our discretion. To the extent
                    allowed under law, these changes may be effective upon
                    notice provided to you. We may change or discontinue any of
                    our Services. We don’t promise to store or keep showing any
                    information and content that you’ve posted. HelloPrivacy is
                    not a storage service. You agree that we have no obligation
                    to store, maintain or provide you a copy of any content or
                    information that you or others provide, except to the extent
                    required by applicable law and as noted in our Privacy
                    Policy.
                  </p>
                </li>
                <li>
                  <strong>Other Content, Sites and Apps</strong>
                  <ol className="list-lowerAlpha">
                    <li>
                      Your use of others’ content and information posted on our
                      Services, is at your own risk.
                    </li>
                    <li>
                      Others may offer their own products and services through
                      HelloPrivacy, and we aren’t responsible for those
                      third-party activities.
                    </li>
                    <li>
                      You may encounter content or information that might be
                      inaccurate, incomplete, delayed, misleading, illegal,
                      offensive or otherwise harmful. HelloPrivacy generally
                      does not review content provided by our Members or others.
                      You agree that we are not responsible for others’
                      (including other Members’) content or information. We
                      cannot always prevent this misuse of our services, and you
                      agree that we are not responsible for any such misuse.
                    </li>
                    <li>
                      You agree you are responsible for deciding if you want to
                      access or use third party apps or sites that link from our
                      Services. Third party apps and sites have their own legal
                      terms and privacy policies, and you may be giving others
                      permission to use your information in ways we would not.
                      Except to the limited extent it may be required by
                      applicable law, HelloPrivacy is not responsible for these
                      other sites and apps, you agree to use these at your own
                      risk.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Limits</strong>
                  <p>
                    HelloPrivacy reserves the right to limit your use of the
                    Services. HelloPrivacy reserves the right to restrict,
                    suspend, or terminate your account if HelloPrivacy believes
                    that you may be in breach of this Contract or law or are
                    misusing the Services (e.g. violating any Do and Don’ts).
                  </p>
                </li>
                <li>
                  <strong>Intellectual Property Rights</strong>
                  <p>
                    HelloPrivacy reserves all of its intellectual property
                    rights in the Services. Using the Services does not give you
                    any ownership in our Services or the content or information
                    made available through our Services. Trademarks and logos
                    used in connection with the Services are be the trademarks
                    of their respective owners. HelloPrivacy and other
                    HelloPrivacy trademarks, service marks, graphics, and logos
                    used for our Services are trademarks or registered
                    trademarks of HelloPrivacy.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>Disclaimer; Limit of Liability and Unique Claims</strong>
              <ol className="list-decimal">
                <li>
                  <strong>No Warranty</strong>
                  <p>
                    TO THE EXTENT ALLOWED UNDER LAW, HELLOPRIVACY AND ITS
                    AFFILIATES (AND THOSE THAT HELLOPRIVACY WORKS WITH TO
                    PROVIDE THE SERVICES) (A) DISCLAIM ALL IMPLIED WARRANTIES
                    AND REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND
                    NON-INFRINGEMENT); (B) DO NOT GUARANTEE THAT THE SERVICES
                    WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C)
                    PROVIDE THE SERVICE (INCLUDING CONTENT AND INFORMATION) ON
                    AN “AS IS” AND “AS AVAILABLE” BASIS.
                  </p>
                  <p>
                    SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL
                    OF THESE DISCLAIMERS MAY NOT APPLY TO YOU.
                  </p>
                </li>
                <li>
                  <strong>Exclusion of Liability</strong>
                  <p>
                    TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS HELLOPRIVACY
                    HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES
                    THIS CONTRACT), HELLOPRIVACY AND ITS AFFILIATES (AND THOSE
                    THAT HELLOPRIVACY WORKS WITH TO PROVIDE THE SERVICES) SHALL
                    NOT BE LIABLE TO YOU OR OTHERS FOR ANY INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF
                    DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES,
                    RELATED TO THE SERVICES (E.G. OFFENSIVE OR DEFAMATORY
                    STATEMENTS, DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR
                    INFORMATION OR CONTENT).
                  </p>
                  <p>
                    IN NO EVENT SHALL THE LIABILITY OF HELLOPRIVACY AND ITS
                    AFFILIATES (AND THOSE THAT HELLOPRIVACY WORKS WITH TO
                    PROVIDE THE SERVICES) EXCEED, IN THE AGGREGATE FOR ALL
                    CLAIMS, AN AMOUNT THAT EXCEEDS THE TOTAL FEES PAID TO
                    HELLOPRIVACY DURING THE 12-MONTH PERIOD BEFORE THE EVENT
                    GIVING RISE TO THE LIABILITY.
                  </p>
                  <p>
                    THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE
                    BARGAIN BETWEEN YOU AND HELLOPRIVACY AND SHALL APPLY TO ALL
                    CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE,
                    CONTRACT, LAW) AND EVEN IF HELLOPRIVACY OR ITS AFFILIATES
                    HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND
                    EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
                  </p>
                  <p>
                    SOME LAWS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                    LIABILITY, SO THESE LIMITS MAY NOT APPLY TO YOU.
                  </p>
                </li>
                <li>
                  <strong>Unique Claims</strong>
                  <p>
                    We both agree that this Contract is personal and unique to
                    you and HelloPrivacy, and each we agree that we will not
                    directly or indirectly attempt to form, aid or abet, or
                    certify a class action or similar group of plaintiffs
                    whether or not constituting a class against you or
                    HelloPrivacy.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>Termination</strong>
              <ol className="list-decimal">
                <li>
                  We reserve the absolute right to reject your participation, or
                  terminate your current participation, in the Website or
                  Concierge Services at any time and for any reason (including,
                  but not limited to, violation of local, state or federal law,
                  misuse or abuse of the software and/or certain convictions,
                  charges or ongoing "lawsuits") or for no reason and without
                  notice to you. Actions that may result in the rejection or
                  removal of your participation can include, but are not limited
                  to: any violation of the terms of this Agreement; your
                  creation, maintenance and/or management of more than one
                  account; your non-payment in full of any unpaid fees; any
                  attempt by you to improperly influence, or cause another to
                  improperly influence the feedback of Users; or any attempt by
                  you to harass, or cause another to harass, or have
                  inappropriate communications with a User.
                </li>
                <li>
                  The following shall survive termination:
                  <ol className="list-lowerAlpha">
                    <li>Our rights to use and disclose your feedback;</li>
                    <li>Sections 4, 6 and 7 of this Contract;</li>
                    <li>
                      Any amounts owed by either party prior to termination
                      remain owed after termination.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Dispute Resolution</strong>
              <p>
                You agree that the laws of the State of Delaware, U.S.A.,
                excluding its conflict of laws rules, shall exclusively govern
                any dispute relating to this Contract and/or the Services. We
                both agree that all of these claims can only be litigated in the
                federal or state courts in New York County, New York, USA, and
                we each agree to personal jurisdiction in those courts. We both
                agree that in any action or proceeding instituted by a party
                arising in whole or in part under, related to, based on or in
                connection with this Contract or the subject matter hereof, the
                prevailing party will be entitled to receive from the losing
                party reasonable attorney's fees, costs and expenses incurred in
                connection therewith, including any appeals there from.
              </p>
            </li>
            <li>
              <strong>General Terms</strong>
              <p>
                If a court with authority over this Contract finds any part of
                it not enforceable, you and us agree that the court should
                modify the terms to make that part enforceable while still
                achieving its intent. If the court cannot do that, you and us
                agree to ask the court to remove that unenforceable part and
                still enforce the rest of this Contract. To the extent allowed
                by law, the English language version of this Contract is binding
                and other translations are for convenience only. This Contract
                (including additional terms that may be provided by us when you
                engage with a feature of the Services) is the only agreement
                between us regarding the Services and supersedes all prior
                agreements for the Services.
              </p>
              <p>
                If we don't act to enforce a breach of this Contract, that does
                not mean that HelloPrivacy has waived its right to enforce this
                Contract. You may not assign or transfer this Contract (or your
                membership or use of Services) to anyone without our consent.
                However, you agree that HelloPrivacy may assign this Contract to
                its affiliates or a party that buys it without your consent.
                There are no third party beneficiaries to this Contract.
              </p>
              <p>
                We reserve the right to change the terms of this Contract and
                will provide you notice if we do and we agree that changes
                cannot be retroactive. If you don't agree to these changes, you
                must stop using the Services.
              </p>
              <p>
                You agree that the only way to provide us legal notice is at the
                address in Section 24.
              </p>
            </li>
            <li>
              <strong>“Dos” and “Don’ts”</strong>
              <ol className="list-decimal">
                <li>
                  <strong>Dos - You agree that you will:</strong>
                  <ol className="list-lowerAlpha">
                    <li>
                      Comply with all applicable laws, including, without
                      limitation, privacy laws, intellectual property laws,
                      anti-spam laws, export control laws, tax laws, and
                      regulatory requirements;
                    </li>
                    <li>
                      Provide accurate information to us and keep it updated;
                    </li>
                    <li>Use your real name on your profile;</li>
                    <li>Use the Services in a professional manner.</li>
                  </ol>
                </li>
                <li>
                  <strong>Don’ts - You agree that you will not:</strong>
                  <ol className="list-lowerAlpha">
                    <li>
                      Act in an unlawful or unprofessional manner in connection
                      with our Services, including being dishonest, abusive or
                      discriminatory;
                    </li>
                    <li>
                      Post inaccurate, defamatory obscene, shocking, hateful,
                      threatening or otherwise inappropriate content or airing
                      personal grievances or disputes;
                    </li>
                    <li>
                      Use an image that is not your likeness or a head-shot
                      photo for your profile;
                    </li>
                    <li>Create a false identity on HelloPrivacy;</li>
                    <li>
                      Misrepresent your identity (e.g. by using a pseudonym),
                      your current or previous positions, qualifications or
                      affiliations with a person or entity;
                    </li>
                    <li>
                      Create a Member profile for anyone other than yourself (a
                      real person);
                    </li>
                    <li>Use or attempt to use another's account;</li>
                    <li>Harass, abuse or harm another person;</li>
                    <li>
                      Send or post any unsolicited or unauthorized advertising,
                      “junk mail,” “spam,” “chain letters” or any form of
                      solicitation unauthorized by HelloPrivacy;
                    </li>
                    <li>
                      Develop, support or use software, devices, scripts,
                      robots, or any other means or processes (including
                      crawlers, browser plugins and add-ons, or any other
                      technology or manual work) to scrape the Services or
                      otherwise copy profiles and other data from the Services;
                    </li>
                    <li>
                      Bypass or circumvent any access controls or Service use
                      limits;
                    </li>
                    <li>
                      Copy, use, disclose or distribute any information obtained
                      from the Services, whether directly or through third
                      parties (such as search engines), without the consent of
                      HelloPrivacy;
                    </li>
                    <li>
                      Use, disclose or distribute any data obtained in violation
                      of this policy;
                    </li>
                    <li>
                      Disclose information that you do not have the consent to
                      disclose (such as confidential information of others
                      (including your employer, school or university));
                    </li>
                    <li>
                      Violate the intellectual property rights of others,
                      including copyrights, patents, trademarks, trade secrets,
                      or other proprietary rights;
                    </li>
                    <li>
                      Violate the intellectual property or other rights of
                      HelloPrivacy, Including, without limitation, (i) copying
                      or distributing our technology; (ii) using the word
                      “HelloPrivacy” or our logos in any business name, email,
                      or URL;
                    </li>
                    <li>
                      Post anything that contains software viruses, worms, or
                      any other harmful code;
                    </li>
                    <li>
                      Reverse engineer, decompile, disassemble, decipher or
                      otherwise attempt to derive the source code for the
                      Services or any related technology that is not open
                      source;
                    </li>
                    <li>
                      Imply or state that you are affiliated with or endorsed by
                      HelloPrivacy without our express consent (e.g.,
                      representing yourself as an accredited HelloPrivacy
                      trainer);
                    </li>
                    <li>
                      Rent, lease, loan, trade, sell/re-sell access to the
                      Services or related data;
                    </li>
                    <li>
                      Sell, sponsor, or otherwise monetize any Service without
                      HelloPrivacy’s consent;
                    </li>
                    <li>
                      Deep-link to our Services for any purpose other than to
                      promote your profile without HelloPrivacy’s consent;
                    </li>
                    <li>
                      Remove any copyright, trademark or other proprietary
                      rights notices contained in or on our Service;
                    </li>
                    <li>
                      Remove, cover or obscure any advertisement included on the
                      Services;
                    </li>
                    <li>
                      Use bots or other automated methods to access the
                      Services;
                    </li>
                    <li>
                      Monitor the Services’ availability, performance or
                      functionality for any competitive purpose;
                    </li>
                    <li>
                      Engage in “framing,” “mirroring,” or otherwise simulating
                      the appearance or function of the Services;
                    </li>
                    <li>
                      Overlaying or otherwise modifying the Services or their
                      appearance;
                    </li>
                    <li>
                      Access the Services except through the interfaces
                      expressly provided by HelloPrivacy, such as its mobile
                      applications and HelloPrivacy.com;
                    </li>
                    <li>
                      Use a Service for tasks that it is not intended for;
                    </li>
                    <li>
                      Override any security feature of the Services; and/or
                    </li>
                    <li>
                      Interfere with the operation of, or place an unreasonable
                      load on, the Services (e.g., spam, denial of service
                      attack, viruses, gaming algorithms).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Service Specific Terms</strong>
              <ol className="list-decimal">
                <li>
                  <strong>Protect Private Info Service</strong>
                  <p>
                    If purchased, the Protect Private Info Service will
                    regularly use your Information to scan online data source
                    websites (the "Data Sites") for personal information about
                    you. Sometimes these websites are called people search
                    sites. If online personal information about you is
                    identified at these Data Sites, we will submit opt-out
                    requests on your behalf to remove it ("Opt-out"). You can go
                    to the HelloPrivacy site at any time to see if online
                    personal information about you was found at these websites
                    and whether the opt-out was successful.
                  </p>
                  <p>
                    We may change the Data Sites that are being scanned at any
                    time without notice to you.
                  </p>
                </li>
                <li>
                  <strong>Limited Authority to Act on Your Behalf</strong>
                  <p>
                    In order to submit an Opt-out request to remove your
                    information from Data Sites, we may need to interact with
                    Data Sites on your behalf. You expressly authorize
                    HelloPrivacy, its agents, servicers, and its employees, to
                    act as your personal representative in order to submit
                    Opt-out requests to Data Sites and:
                  </p>
                  <ol className="list-lowerAlpha">
                    <li>obtain information on your behalf;</li>
                    <li>submit your personal information to the Data Sites;</li>
                    <li>
                      communicate with the Data Sites or other third parties on
                      your behalf;
                    </li>
                    <li>
                      agree to applicable terms and conditions imposed by these
                      Data Sites;
                    </li>
                    <li>
                      complete and sign or execute documents on your behalf; and
                    </li>
                    <li>
                      take other actions that we believe are reasonably
                      necessary to complete an Opt-out request from a Data Site
                      or to implement the Service.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>No Guarantee</strong>
                  <p>
                    HelloPrivacy does not guarantee that the Service will find a
                    user’s online personal information at any of the Data Sites,
                    or that it will scan all Data Sites if a user’s online
                    personal information is not present. We do not guarantee
                    that the Service will be effective, that an Opt-out request
                    will be honored or responded to, or that all Opt-out
                    requests for a user’s online personal information will be
                    equivalent. We do not guarantee that an Opt-out request will
                    prevent all or any future uses or disclosures of a user’s
                    online personal information by a Data Site, that we will
                    identify all instances of a user’s online personal
                    information that are eligible for Opt-out, or that the Data
                    Sites will not retain copies of a user’s online personal
                    information.
                  </p>
                  <p>
                    Some of the Data Sites may have multiple databases and may
                    acquire and aggregate personal information about individuals
                    on an ongoing basis. The Opt-out request we submit on your
                    behalf may not be effective for all sources of online
                    personal information maintained by the Data Site, or for
                    online personal information acquired by the Data Site after
                    we submit the Opt-out request. In addition, HelloPrivacy may
                    not locate all entries or the correct entries of your online
                    personal information that are eligible for Opt-out. Not all
                    of these Data Sites offer the same type of Opt-out options.
                    Further, in some instances, even if we are able to
                    effectively submit an Opt-out request on your behalf to a
                    Data Site, that Data Site may still maintain your online
                    personal information in its records. In other instances, a
                    Data Site may fail to honor or properly respond to an
                    Opt-out request.
                  </p>
                  <p>
                    You understand and agree that HelloPrivacy is not
                    responsible for the actions or inactions of any of these
                    Data Sites, and that HelloPrivacy makes no representations,
                    warranties or guarantees related to such Data Sites. You
                    expressly agree that HelloPrivacy will not be liable to you
                    under any circumstances for any actions or inactions of
                    these Data Sites, or for our failure for any reason to
                    exercise effective Opt-outs on your behalf. You understand
                    and agree that HelloPrivacy is not a credit repair
                    organization as defined under federal or state law,
                    including the Credit Repair Organizations Act.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>Complaints Regarding Content</strong>
              <p>
                HelloPrivacy will respond to notices of alleged copyright
                infringement and terminate accounts of repeat infringers
                according to the process set out in the U.S. Digital Millennium
                Copyright Act. If you have a good faith belief that your work
                has been copied in a way that constitutes copyright
                infringement, or that your intellectual property rights have
                been otherwise violated, you can submit a notice of claimed
                infringement to the Company’s designated agent by email at{' '}
                <a href="mailto:privacysupport@helloprivacy.com">
                  privacysupport@helloprivacy.com
                </a>
                . The notice must contain, at least, the following information:
              </p>
              <ul className="list-circle">
                <li>
                  An identification of the copyrighted work claimed to have been
                  infringed.
                </li>
                <li>
                  An identification of the material that is claimed to be
                  infringing and information reasonably sufficient to permit the
                  Company to locate the material.
                </li>
                <li>
                  Information reasonably sufficient to permit the Company to
                  contact you, such as an address, telephone number, and, if
                  available, an email address.
                </li>
                <li>
                  An express statement that you have a good faith belief that
                  use of the material in the manner complained of is not
                  authorized by the copyright owner.
                </li>
                <li>
                  An express statement that the information in the notice is
                  accurate, and under penalty of perjury, that you are
                  authorized to act on behalf of the owner of the copyright that
                  you claim is being infringed upon.
                </li>
                <li>
                  A physical or electronic signature (i.e., /s/NAME) of the
                  owner or person authorized to act on behalf of the owner of
                  the copyright that you claim is being infringed upon.
                </li>
              </ul>
            </li>
            <li>
              <strong>How To Contact Us</strong>
              <p>
                If you want to send us notices or service of process, please
                contact us at: 53 West James Street, Suite 401, Lancaster, PA
                17603, Attn: Notice.
              </p>
            </li>
          </ol>

          <p className="text-xs font-mono my-16">4845-5196-8842, v. 4</p>
        </div>
        <Footer />
      </div>
    </>
  )
}
